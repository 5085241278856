<script src="../../../../api/common/dict.js"></script>
<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<style scoped>
.w20 {
  width: 20%;
}

.w20:last-child {
  width: 19%;
}

.w33 {
  width: 33%;
}

.w50 {
  width: 50%;
}

.w66 {
  width: 66%;
}

.w-99 {
  width: 99%;
}

.w100 {
  width: 100%;
}

.guding-top {
  width: 66%;
  min-height: 200px;
}

.top-height {
  line-height: 200px;
}

.less-width .w-50 {
  width: 49.5% !important;
}

.dragArea {
  padding: 30px 0;
  display: flex;
}

.msgContent {
  border: unset;
}

.msgName {
  width: 120px;
}

.msgName {
  /* border: unset; */
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 15px;
}
.grey-time {
  width: 36%;
}

.heighLabel {
  height: 132px;
  line-height: 132px;
}
.flexList {
  align-items: stretch;
}
.msgLabel {
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  min-height: 40px;
  line-height: 40px;
}

.border-diff .msgLabel {
  border-right: unset;
}

.msgContent.border-diff .msgLabel:nth-child(n + 5) {
  border-bottom: unset;
}

.less-width .msgLabel {
  border-bottom: unset;
}

.d-border-bottom {
  border-bottom: 1px solid #dfdfdf;
}

.canmove {
  cursor: move;
}

.msgContent .msgLabel {
  border-top: unset;
}

.msgVal {
  padding: 0 10px;
}

.msgContent .msgVal {
  border-top: unset;
}

.list-group-item {
  background-color: rgb(255, 252, 239);
  cursor: pointer;
}

/deep/ .el-select.blueBorder .el-input__inner {
  border-color: rgb(23, 118, 210);
}

/deep/.el-input--prefix .el-input__inner {
  line-height: 30px;
  height: 30px;
}

.guding-col {
  flex-wrap: wrap;
  border-bottom: 1px solid #dfdfdf;
  box-sizing: border-box;
}

.guding-col .msgLabel:nth-child(n-1) {
  border-bottom: unset;
}

.border-top {
  border-top: 1px solid #dfdfdf !important;
}

.border-right {
  border-right: 1px solid #dfdfdf !important;
}

.guding-img {
  width: 33%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfdfdf !important;
}

/deep/ .guding-img {
  border: 1px solid #dfdfdf !important;
}
@media screen and (min-width: 1200px) {
  .msgName {
    min-width: 120px;
    width: 120px;
  }
}

.db-font {
  color: #f56c6c;
  font-size: 10px;
  line-height: 1;
  padding-top: 4px;
  position: absolute;
  top: 100%;
  left: 0;
}

.auto-scroll {
  overflow-y: auto;
}

/deep/.el-radio-group label {
  margin-bottom: 0;
}

.notice {
  height: 50px;
  overflow-y: auto;
}

.closeIcon .iconfont {
  font-size: 18px;
}

.closeIcon .iconfont:hover {
  color: red;
}
.sty__title {
  width: 99%;
  height: 50px;
  line-height: 30px;
  overflow-y: auto;
  text-align: center;
  border: 1px dashed rgb(187, 187, 187);
  font-size: 16px;
  color: #000000;
}  
/deep/[id^="__BVID__97___BV_modal_outer_"] {
    z-index: 200 !important;
}
.edui-default .edui-dialog {
    z-index: 8000 !important;
    position: absolute;
}
</style>
<script>
import Layout from "@/views/layouts/main";
import CheckHeader from "@/components/check-header";
import ExamHeader from "./exam-header";
import draggable from "vuedraggable";
import YzSelect from "@/components/form/yzSelect.vue";
import yzFormItem from "@/components/form/yzFormItem.vue";
import examForm from "@/components/form/examForm.vue";

import $ from "jquery";
import { getDicts } from "@/api/common/dict.js";
import { getExamDetails, editExamRecord } from "@/api/admin/exam/examRecord.js";
import { covertToSql, covertToForm } from "@/api/common/draggable.js";
import {
  getTemplateList,
  addTemplateList,
  deleteTemplate,
  saveTemplateList,
  getTemplateListByKsbmh,
  getTemplateName,
  saveTemplate,
  importTemplate,
  deleteModule,
  updateTemplate,
} from "@/api/admin/exam/examTemplate.js";

// import {
//   createKsbmb
// } from "@/api/admin/exam/createExamku.js"
export default {
  name: "clone-on-control",
  display: "Clone on Control",
  instruction: "Press Ctrl to clone element from list 1",
  order: 4,
  components: {
    draggable,
    YzSelect,
    Layout,
    CheckHeader,
    yzFormItem,
    ExamHeader,
    examForm,
  },
  data() {
    return {
      title: "考试报名信息管理/",
      title2: "",
      items: [
        {
          text: "用户首页",
          href: "/admin",
        },
        {
          text: "考试模板",
          active: true,
        },
      ],
      sid: "",
      form: {},
      chooseId: {},
      model: [],
      moudleList: [],
      mid: "",
      mbmc: "",
      dialogTitle: "",
      mouleName: "",
      list1: [],
      list2: [],
      list3: [],
      ksbmbh: "",
      ksbmb: "",
      keyword: "",
      isDisabled: false,
      isNameDisabled: false,
      isSaveMold: false,
      listChoose: [],
      controlOnStart: true,
      showComp: false,
      examForm: {
        kstsxx: "",
        ksbzxx: "",
        ksmc: "",
        dymc: "",
      },
      minHeight: 0,
      addForm: {
        name: "",
        icon: "",
        must: true,
        type: "select",
        width: "w50",
        bound: ["hyztm", "hyztmc"],
        dict: "dict_hyzt",
      },
      gdList: [],
      gudingList: [
        {
          name: "姓名",
          val: "xm",
          zdbl: 50,
        },
        {
          name: "性别",
          val: "xbm",
          zdbl: 50,
        },
        {
          name: "证件类型",
          val: "sfzjlxm",
          zdbl: 50,
        },
        {
          name: "证件号码",
          val: "sfzjh",
          zdbl: 50,
        },
        {
          name: "出生年月",
          val: "csrq",
          zdbl: 50,
        },
        {
          name: "民族",
          val: "mzm",
          zdbl: 50,
        },
      ],
      adddw: [
        {
          name: "报考单位",
          val: "bkdwzym",
          zdbl: 50,
        },
        {
          name: "报考岗位",
          val: "bkgwxkm",
          zdbl: 50,
        },
      ],
      addxk: [
        {
          name: "报考专业",
          val: "bkdwzym",
          zdbl: 100,
        },
      ],
      readOnly: false,
      useModelName: "",
      isReloadData: true,
      nullForm: {},
      showUeditor: false,
    };
  },
  methods: {
    chooseEle(e) {
      this.chooseId = e;
      this.$forceUpdate();
    },
    wChange() {
      this.$forceUpdate();
    },
    // 根据中间的高度动态显示两侧高度
    changeCenter(num = 0) {
      let currentHeight = this.$refs.main.$el.clientHeight;
      this.minHeight = currentHeight + num;
    },
    // 提交创建好的模板
    success() {
      this.listChoose = [];
      this.list2.forEach((item) => {
        item["zdfl"] = 1;
        this.listChoose.push(item);
      });
      this.list3.forEach((item) => {
        item["zdfl"] = 2;
        this.listChoose.push(item);
      });
      
      const mold = this.listChoose.map((d,idx)=>({...d, wzxh:idx}));

      if (mold && mold.length > 0) {
        let ksbmbh = this.$route.query.ksbmbh;
        if (this.isSaveMold && this.mouleName) {
          if (this.mouleName) {
            saveTemplate(this.mouleName, mold).then((res) => {
              if (res.status) {
                this.$message({
                  type: "success",
                  message: "模板已成功保存",
                });
              }
            });
          } else {
            this.$message({
              type: "warning",
              message: "请先命名该模板！",
            });
            return;
          }
        }
        // 提交报名表提示语
        saveTemplateList(ksbmbh, mold).then((res) => {
          if (res.status) {
            this.$refs.header.getUrl("/admin/examEnrollProgress");
          }
        });
        let formDate = {
          sid: this.examForm.sid,
          kstsxx: this.examForm.kstsxx,
          ksbzxx: this.examForm.ksbzxx,
          dymc: this.examForm.dymc,
        };
        if (this.examForm.bmkbm) {
          this.$message({
            type: "warning",
            message: "需重新生成报名库，拖动才生效！",
          });
        }
        //是否使用的保存的新模板
        if (this.isSaveMold && this.mouleName) {
          formDate.sfsymb = 1;
          formDate.mbmc = this.mouleName;
        }
        //是否下拉模板
        else if (this.mbmc) {
          formDate.sfsymb = 1;
          formDate.mbmc = this.mbmc;
        }

        editExamRecord(formDate).then(() => {});
      } else {
        this.$message({
          type: "warning",
          message: "请拖动组件创建表单",
        });
      }
    },
    clone(e) {
      this.chooseId = JSON.parse(JSON.stringify(e));
      return this.chooseId;
    },
    pullFunction() {
      return this.controlOnStart ? "clone" : true;
    },
    start({ originalEvent }) {
      this.controlOnStart = originalEvent.ctrlKey;
    },
    addEnd(e) {
      if (this.$refs["list1Container"].$el.clientHeight > 210) {
        var a = this.list2[e.newIndex];
        this.list2.splice(e.newIndex, 1);
        this.list1.push(a);
        this.$message({
          type: "warning",
          message: "该容器已满，请将组件拖到下面的容器中！",
        });
      }
      this.changeCenter();
    },
    addComp() {
      this.showComp = true;
      this.dialogTitle = "增加组件";
      this.addForm = {
        name: "",
        icon: "",
        must: false,
        type: "select",
        width: "w50",
        bound: "",
        dict: "",
        length: "",
        glzdb: 0,
      };
    },
    editItem(data) {
      let copyData = JSON.parse(JSON.stringify(data));

      this.addForm = covertToForm([copyData])[0];

      this.showComp = true;
      this.dialogTitle = "修改组件";
      if (data.sfxtzd != 1) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    // 加载字典数据
    loadDict(list) {
      let idx = [];
      let dicts = [];
      for (let i in list) {
        let item = list[i];
        if ((item.glzdb || item.glzdb == null) && item.zdbmc != "zgxlm") {
          idx.push(i);
          dicts.push(item.dict);
        } else {
          if (item.zdkxz != null) {
            let s1 = this.element.zdkxz.split(",")[0].split("|");
            let s2 = "";
            if (this.element.zdkxz.split(",")[1]) {
              s2 = this.element.zdkxz.split(",")[1].split("|");
            } else {
              s2 = this.element.zdkxz.split(",")[0].split("|");
            }
            item["data"] = [];
            for (let i in s1) {
              item.data.push({
                name: s2[i],
                value: s1[i],
              });
            }
            this.$forceUpdate();
          }
        }
      }
      if (dicts.length != 0) {
        getDicts(...dicts).then((res) => {
          for (let i in dicts) {
            let id = idx[i];
            let dictName = dicts[i];
            list[id]["data"] = res[dictName];
            this.$forceUpdate();
          }
        });
      }
    },

    // 渲染创建好的模板
    getTemplateList(type) {
      getTemplateList().then((res) => {
        if (res.status) {
          this.gdList = JSON.parse(JSON.stringify(this.gudingList));
          this.list1 = [];
          this.list2 = [];
          this.list3 = [];
          var data = res.data;
          let func;
          let param;
          if (type == 1) {
            func = getTemplateListByKsbmh;
            param = this.ksbmbh;
          }
          if (type == 2) {
            func = importTemplate;
            param = this.mid;
          }
          func(param).then((res1) => {
            if (res1.status) {
              data = data.filter((item) => {
                let flag = true;
                for (let i in res1.data) {
                  if (res1.data[i].zddm == item.zddm) {
                    res1.data[i].sfxtzd = item.sfxtzd;
                    flag = false;
                  }
                }
                return flag;
              });
              res1.data.forEach((item) => {
                // console.log(item)
                if (this.examForm.zpysyq != 4) {
                  if (item.zdfl == 1) {
                    this.list2.push(item);
                  } else {
                    this.list3.push(item);
                  }
                } else {
                  this.list3.push(item);
                }
              });
              this.list1 = data;
              if (this.examForm.kslxmc == "其他申请报名") {
                this.list1.unshift({
                  sfbt: true,
                  zddm: "xm",
                  zdmc: "姓名",
                  data: "",
                  zdfl: "1",
                  txlx: "input",
                  zdbl: "50",
                  readOnly: true,
                  json: "xm",
                  sfxtzd: 1,
                  zdcd: 50,
                });
              }else {

                if (this.examForm.kmxzfs == "1") {
                  this.gdList.unshift(...this.addxk);
                } else {
                  this.gdList.unshift(...this.adddw);
                }
              }
              this.gdList.forEach((k) => {
                this.list1.forEach((v, index) => {
                  if (JSON.stringify(v.zddm) == JSON.stringify(k.val)) {
                    this.list1.splice(index, 1);
                  }
                });
              });
            }
          });
        }
      });
    },

    deleteTemplate(sid) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTemplate(sid).then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.list1 = this.list1.filter((item) => {
                return item.sid != sid;
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    checkAddComp() {
      if (this.addForm.bound) {
        if (this.addForm.length > 0) {
          addTemplateList(covertToSql([this.addForm])[0]).then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "新增成功",
              });
              this.showComp = false;
              this.list1.push(res.data);
            }
          });
        } else {
          this.$message({
            type: "warning",
            message: "字段最大长度必须大于0",
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: "请先绑定字段",
        });
      }
    },
    EditComp() {
      if (this.addForm.bound) {
        let formData = JSON.parse(JSON.stringify(this.addForm));
        formData.sid = this.addForm.id;
        if (this.addForm.length > 0) {
          updateTemplate(covertToSql([formData])[0]).then((res) => {
            // console.log(res)
            if (res.status) {
              this.list1.forEach((v, i) => {
                if (v.sid == this.addForm.id) {
                  this.list1[i] = covertToSql([
                    JSON.parse(JSON.stringify(this.addForm)),
                  ])[0];
                }
              });
              this.$message({
                type: "success",
                message: "修改成功",
              });
              this.showComp = false;
            }
          });
        } else {
          this.$message({
            type: "warning",
            message: "字段最大长度必须大于0",
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: "请先绑定字段",
        });
      }
    },
    submit() {
      if (this.addForm.id) {
        this.EditComp();
      } else {
        this.checkAddComp();
      }
    },
    // 模板下拉
    getMould() {
      getTemplateName().then((res) => {
        if (res.status) {
          this.moudleList = res.data;
        }
      });
    },
    // 模板导入
    importMould() {
      this.$confirm("此操作将覆盖您当前的表单样式, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // this.addxk=[]
        // this.adddw=[]
        this.useModelName = this.mbmc;

        this.getTemplateList(2);
      });
    },
    changeChecked() {
      this.isSaveMold = !this.isSaveMold;
    },
    async getDetail(ksbmbh) {

      if (this.ksbmbh) {
        const res = await getExamDetails(ksbmbh).then((res) => res);
        this.examForm = res.data;
        // console.log(this.examForm)
        //使用了模板
        if (this.examForm.sfsymb == 1) {
          this.useModelName = this.examForm.mbmc
              ? this.examForm.mbmc
              : this.useModelName;
        }
        if (this.examForm.zpysyq == 4) {
          $(".guding-top").css("width", "99%");
          $(".guding-top").addClass("border-right");
          $(".guding-img").css("display", "none");
        }
        if (this.examForm.kslxmc == "其他申请报名") {
          // $(".guding-top").css("display", "none");
          this.gudingList = [];
        } else {
          $(".guding-top").css("display", "");
        }

        this.getTemplateList(1);
      }else {
        this.getTemplateList(1);
      }



    },
    getmbmc() {
      let val = this.mid;
      let obj = this.moudleList.find((k) => {
        return k.mid === val;
      });
      this.mbmc = obj.mbmc;
    },
    // 删除模板
    deleteMod(mid) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteModule(mid).then((res) => {
          if (res.status) {
            this.$message({
              type: "success",
              message: "删除成功",
            });
            this.mid = "";
            this.getMould();
          }
        });
      });
    },
    // 获取Form
    getForm(e) {
      // console.log(e)
    },
    //模板预览
    mouldPreview() {
      let routeUrl = this.$router.resolve({
        path: "/admin/examMouldPreview",
        query: {
          sid: this.sid,
          mid: this.mid,
          mbmc: this.mbmc,
        },
      });
      if (this.mid) {
        window.open(routeUrl.href, "_blank");
      } else {
        this.$message({
          type: "warning",
          message: "请选择需要预览的模板！",
        });
      }
    },
    // 打印预览
    printPreview() {
      $("#pdf").css("display", "");

      this.$nextTick(() => {
        this.$message({
          type: "success",
          message: "数据生成中，请耐心等待",
        });
        $("#printId").css("display", "");
        this.toPdfHtml("printId", "pdf1", "pdf");
        $("#pdf").css("display", "none");
        this.ExportSavePdf("报名信息表", "printId", 0, false, "printId");
      });
    },

    // 打印前的处理
    toPdfHtml(id, id1, id2) {
      let width = $("#" + id).outerWidth();
      this.pageHeight = Math.floor((width / 210) * 297);
      if ($("#" + id1).length > 0) {
        return false;
      }
      this.pdfNum = 1;
      this.pdfHeight = 0;
      $("#" + id).append("<div id='" + id1 + "'></div>");
      $("#" + id1).append(
        "<div class='pdf' id='" +
          id1 +
          "1' page='1' style='height:" +
          this.pageHeight +
          "px'></div>"
      );
      //
      if ($("#" + id2 + " .kstitle").length > 0) {
        this.addPdfItem(
          $("#" + id2 + " .kstitle"),
          $("#" + id2 + " .kstitle").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .jbxx").length > 0) {
        this.addPdfItem(
          $("#" + id2 + " .jbxx .title"),
          $("#" + id2 + " .jbxx .title").outerHeight(true),
          id1
        );
        let itemhead = $("#" + id2 + " .jbxx .value").find(
          ".person-form.outerCotent>.msgContent>div:first-child"
        );
        this.addPdfItem($(itemhead), $(itemhead).outerHeight(true), id1);
        let itemfoot = $("#" + id2 + " .jbxx .value").find(
          ".person-form.outerCotent>.msgContent>div:last-child"
        );
        $(itemfoot)
          .children()
          .children()
          .each((i, item) => {
            this.addPdfItem(item, $(item).outerHeight(), id1);
          });
      }
      if ($("#" + id2 + " .bkxx").length > 0) {
        this.addPdfItem(
          $("#" + id2 + " .bkxx .title"),
          $("#" + id2 + " .bkxx .title").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .msxx").length > 0) {
        this.addPdfItem(
          $("#" + id2 + " .msxx"),
          $("#" + id2 + " .msxx").outerHeight(true),
          id1
        );
      }
      if ($("#" + id2 + " .bmzt").length > 0) {
        this.addPdfItem(
          $("#" + id2 + " .bmzt"),
          $("#" + id2 + " .bmzt").outerHeight(true),
          id1
        );
      }
    },
    addPdfItem(item, height, id, imgurl) {
      let paddingHeight = 60;
      height = Number(height);
      if (
        $(item).hasClass("msgLabel") &&
        !$(item).hasClass("w100") &&
        this.row == 0
      ) {
        this.row = 1;
      } else if (
        $(item).hasClass("msgLabel") &&
        !$(item).hasClass("w100") &&
        this.row != 0
      ) {
        this.oldHeight = height;
        this.row = 0;
        height = 0;
      }
      if (
        $(item).hasClass("msgLabel") &&
        $(item).hasClass("w100") &&
        this.row != 0
      ) {
        // this.pdfHeight += this.oldHeight;
        this.oldHeight = 0;
        this.row = 0;
      }

      // 当前pdf高度大于0，且未添加新对象高度前后的页数不一致则换页
      if (
        this.pdfHeight > 0 &&
        parseInt((this.pdfHeight + paddingHeight) / this.pageHeight) !=
          parseInt((this.pdfHeight + height + paddingHeight) / this.pageHeight)
      ) {
        // pdf页数加1
        this.pdfNum += 1;
        this.pdfHeight = (this.pdfNum - 1) * this.pageHeight;
        $("#" + id).append(
          "<div class='pdf' id='" +
            id +
            this.pdfNum +
            "' page='" +
            this.pdfNum +
            "'style='height:" +
            this.pageHeight +
            "px'></div>"
        );
      }

      this.pdfHeight += height;
      // 将内容放入div中
      if ($(item).hasClass("msgLabel")) {
        if ($("#" + id + this.pdfNum + " .flexBox").length) {
          $(item)
            .clone()
            .appendTo($("#" + id + this.pdfNum + " .flexBox"));
        } else {
          $("#" + id + this.pdfNum).append(
            "<div class='d-flex flex-wrap flexBox'></div>"
          );
          $(item)
            .clone()
            .appendTo($("#" + id + this.pdfNum + " .flexBox"));
        }
      } else {
        $(item)
          .clone()
          .appendTo($("#" + id + this.pdfNum));
      }
      $("#" + id + " .pdf").css("padding", "30px 25px");
      $("#" + id + " .pdf").css("color", "black");
      if (imgurl) {
        $("#" + id + " .pdf").css("background-image", "url(" + imgurl + ")");
      }
    },
  },

  mounted() {
    this.ksbmbh = this.$route.query.ksbmbh;

      this.getDetail(this.ksbmbh);

    this.getMould();
    let that = this;
    setTimeout(function () {
      that.changeCenter();
    }, 500);

    this.form = {
      djsdm: "01",
      djsmc: "市辖区",
      dzxx: "",
      gatqwm: "04",
      gatqwmc: "澳门同胞亲属 ",
      gjdqm: "158",
      gjdqmc: "台湾",
      hjszdm: "12",
      hjszdmc: "天津市",
      hyztm: "21",
      hyztmc: "初婚",
      jkzkm: "1",
      jkzkmc: "健康或良好",
      jtzz: "",
      mzm: "04",
      mzmc: "藏族",
      qxdm: "120101",
      qxmc: "天津市|市辖区|和平区",
      ssdm: "12",
      ssmc: "天津市",
      xscfjl: "",
      xyzjm: "10",
      xyzjmc: "佛教",
      yddh: "",
      ywxscfjl: "1",
      yzbm: "",
      zgxlm: "",
      zgxlmc: "",
      zgxlxxfs: "1",
      zzmmm: "03",
      zzmmmc: "共青团员",
    };
  },
  created() {
    this.sid = this.$route.query.sid;
  },
};
</script>
<template>
  <Layout>
    <CheckHeader :title="title" :title2="examForm.ksmc" :items="items" />
    <ExamHeader :ksbmbh="ksbmbh" ref="header" />
    <div class="card" style="min-height: 470px">
      <div class="card-body">
        <div class="flexList">
          <el-select
            class="mr-2 blueBorder"
            style="width: 300px"
            placeholder="请选择系统已有的模板"
            v-model="mid"
            size="small"
            @change="getmbmc"
          >
            <el-option
              :value="item.mid"
              v-for="item in moudleList"
              :key="item.value"
              :label="item.mbmc"
            >
              {{ item.mbmc }}
              <span
                class="float-right closeIcon"
                @click.stop="deleteMod(item.mid)"
                ><i class="iconfont icon-guanbi"></i>
              </span>
            </el-option>
          </el-select>
          <div>
            <button
              type="button"
              class="btn btn-info h30 mb-0"
              for="customFile"
              @click="importMould"
            >
              <i class="iconfont icon-mb-search mr-2 font-size-18"></i>导入
            </button>
          </div>
          <button
            type="button"
            class="btn btn btn-outline-info h30 ml-2"
            @click="mouldPreview"
          >
            预览
          </button>
          <div @click="readOnly = !readOnly">
            <button
              type="button"
              class="btn btn btn-outline-info h30 ml-2"
              v-if="!readOnly"
            >
              查看只读
            </button>
            <button
              type="button"
              class="btn btn btn-outline-info h30 ml-2"
              v-else
            >
              查看可编辑
            </button>
          </div>
          <span class="red-font ml-2"
            >提示：报名一旦开启，则无法编辑报名表</span
          >
          <span class="text-info ml-2"
            >使用模版：
            <span v-if="useModelName">{{ useModelName }}</span>
            <span v-else>暂未使用模板</span></span
          >
          <button
            type="button"
            class="btn btn btn-outline-info h30 ml-2"
            @click="this.printPreview"
          >
            打印预览
          </button>
        </div>
        <div class="outerCotent mt-3">
          <div class="style-set-box">
            <div class="style-top flexList w-100">
              <div class="col-lg-2 p-0">字段区域</div>
              <div class="col-lg-8 p-0">表单样式</div>
              <div class="col-lg-2 p-0">参数设置</div>
            </div>
            <el-row>
              <el-col :span="4">
                <div class="list-group-item">
                  <div
                    class="style-info flexList justify-content-between"
                    @click="addComp"
                  >
                    <div>
                      <i
                        class="el-icon-circle-plus-outline mr-2 font-size-16"
                      ></i
                      >增加组件
                    </div>
                  </div>
                  <div>
                    <input
                      type="text"
                      name=""
                      id=""
                      placeholder="请输入相关组件查询"
                      value=""
                      class="form-control mt-2 h30"
                      v-model="keyword"
                    />
                  </div>
                </div>
                <draggable
                  class="dragArea list-group style-left auto-scroll"
                  :list="list1"
                  :clone="clone"
                  :style="[{ height: minHeight - 93 + 'px' }]"
                  :group="{ name: 'people', pull: pullFunction }"
                  @start="start"
                  v-if="isReloadData"
                >
                  <div
                    class="list-group-item"
                    v-for="element in list1"
                    :key="element.sid"
                    v-show="!keyword || element.zdmc.indexOf(keyword) > -1"
                  >
                    <div
                      class="style-info flexList justify-content-between"
                      @click="editItem(element)"
                    >
                      <div
                        class="line1 w-50"
                        :title="element.zdmc"
                        :class="[element.sfxtzd != 1 ? 'text-info' : '']"
                      >
                        {{ element.zdmc }}
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'input'">
                        输入框
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'select'">
                        下拉框
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'switch'">
                        开关
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'radio'">
                        单选框
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'textarea'">
                        文本域
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'date'">
                        日期
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'month'">
                        月份
                      </div>
                      <div
                        class="grey-time"
                        v-if="
                          element.txlx == 'local' || element.txlx == 'local1'
                        "
                      >
                        地区
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'selectEx'">
                        级联下拉框
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'file'">
                        文件
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'shyj'">
                        审核意见
                      </div>
                      <div class="grey-time" v-if="element.txlx == 'bmsm'">
                        报名声明
                      </div>
                      <i
                        class="el-icon-delete-solid"
                        v-if="element.sfxtzd != 1"
                        @click.stop="deleteTemplate(element.sid)"
                      ></i>
                    </div>
                  </div>
                </draggable>
              </el-col>
              <el-col :span="16" class="style-right" ref="main" id="mians">
                <textarea
                  rows="2"
                  cols=""
                  class="sty__title form-control"
                  v-model="examForm.dymc"
                  placeholder="考试报名名称"
                ></textarea>

                <div class="w-99">
                  <textarea
                    rows="2"
                    cols=""
                    class="notice w-100 form-control"
                    v-model="examForm.kstsxx"
                    placeholder="请填写报名注意事项"
                  ></textarea>
                </div>
                <div class="mt-3 exam-form">
                  <el-row style="display: flex;" class="border-diff top-height">
                    <el-col ref="list1Container" class="guding-top">
                      <div class="flexList w-100 guding-col">
                        <!-- <div class="w-100">
                          <div class="flexList w-100 msgLabel " v-if="examForm.kmxzfs==2">
                            <div class="msgName">报考单位
                            </div>
                            <div class="msgVal selectShow flexList border-top">
                            </div>
                          </div>
                          <div class="flexList w-100 msgLabel " v-if="examForm.kmxzfs==1">
                            <div class="msgName">报考专业</div>
                            <div class="msgVal selectShow flexList border-top">
                            </div>
                          </div>
                        </div> -->
                        <div
                          class="flexList msgLabel"
                          v-for="(item, index) in gdList"
                          :key="index"
                          style="    align-items: stretch;"
                          :class="['w' + item.zdbl]"
                        >
                          <div class="msgName">{{ item.name }}</div>
                          <div class="msgVal"></div>
                        </div>
                      </div>
                      <draggable
                        class="msgContent border-diff flexList fs-xs"
                        :list="list2"
                        group="people"
                        style="min-height: 40px"
                        @add="addEnd"
                      >
                        <div
                          @click="chooseEle(element)"
                          class="flexList msgLabel canmove"
                          :class="[
                            'w' + element.zdbl,
                            element.txlx == 'textarea' ||
                            element.txlx == 'shyj' ||
                            element.txlx == 'bmsm'
                              ? 'msgtextarea'
                              : '',
                          ]"
                          v-for="element in list2"
                          :key="element.sid"
                        >
                          <div class="msgName">
                            <span style="color: red" v-if="element.sfbt">*</span
                            >{{ element.zdmc }}
                          </div>
                          <div class="msgVal selectShow flexList">
                            <yzFormItem
                              :innerElement="element"
                              v-model="form"
                              @change="getForm"
                              :readOnly="readOnly"
                            >
                            </yzFormItem>
                            <!--                            <div v-show="element.txlx=='file'" style="line-height:19px">{{element.tsxx}}</div>-->
                          </div>
                        </div>
                      </draggable>
                    </el-col>
                    <el-col class="guding-img">
                      <img
                        :src="require('@/assets/images/person/person-none.png')"
                        alt=""
                        style="width: 120px; height: 160px"
                      />
                    </el-col>
                  </el-row>
                  <el-row>
                    <draggable
                      style="width: 99%; flex-wrap: wrap"
                      class="flexList fs-xs less-width"
                      :style="{
                        minHeight:
                          examForm.kslxmc == '其他申请报名' ? '200px' : '40px',
                      }"
                      :list="list3"
                      @add="addEnd"
                      group="people"
                      :class="[list3.length ? 'd-border-bottom' : '']"
                    >
                      <div
                        @click="chooseEle(element)"
                        class="flexList msgLabel canmove"
                        :class="[
                          'w' + element.zdbl,
                          element.txlx == 'textarea' ||
                          element.txlx == 'shyj' ||
                          element.txlx == 'bmsm'
                            ? 'msgtextarea'
                            : '',
                        ]"
                        style="align-items: stretch;"
                        v-for="element in list3"
                        :key="element.sid"
                      >
                        <div class="msgName">
                          <span style="color: red" v-if="element.sfbt">*</span
                          >{{ element.zdmc }}
                        </div>
                        <div class="msgVal selectShow flexList">
                          <yzFormItem
                            :innerElement="element"
                            v-model="form"
                            @change="getForm"
                            :readOnly="readOnly"
                            :text="element.tsxx"
                          >
                          </yzFormItem>
                          <!--                          <div v-show="element.txlx=='file'"  style="line-height: 19px">{{element.tsxx}}</div>-->
                        </div>
                      </div>
                    </draggable>
                    <div class="w-99">
                      <textarea
                        rows="2"
                        cols=""
                        class="notice w-100 form-control"
                        v-model="examForm.ksbzxx"
                        placeholder="请填写考试备注信息   "
                      ></textarea>
                    </div>
                  </el-row>
                </div>
              </el-col>
              <el-col
                :span="4"
                class="mould-right"
                :style="[{ height: minHeight + 'px' }]"
              >
                <div class="param-name">
                  <i
                    class="iconfont icon-riLine-team-line mr-2 font-size-18"
                  ></i
                  >{{ chooseId.zdmc }}
                </div>
                <div class="mt-3 exam-form">
                  <div class="param-box">
                    <div class="param-name">标题</div>
                    <div class="col-sm-10 p-0 flexList">
                      <input
                        v-model="chooseId.zdmc"
                        class="form-control w-100 h30"
                      />
                    </div>
                  </div>
                  <div class="param-box">
                    <div class="param-name">提示</div>
                    <div class="col-sm-10 p-0 flexList">
                      <textarea rows="3"
                        style="line-height: 1.5;"
                        v-model="chooseId.tsxx"
                        @click="showUeditor = true"
                        class="form-control w-100 h30"
                      />
                    </div>
                  </div>
                  <div class="param-box">
                    <div class="param-name">是否必填项</div>
                    <div class="col-sm-10 p-0 flexList">
                      <yz-select
                        :dict="[
                          { name: '必填', value: true },
                          { name: '非必填', value: false },
                        ]"
                        :bound="['sfbt']"
                        class="w-100"
                        v-model="chooseId"
                        @change="$forceUpdate()"
                      ></yz-select>
                    </div>
                  </div>
                  <div class="param-box">
                    <div class="param-name">字段比例</div>
                    <div class="col-sm-10 p-0 flexList">
                      <!--   <yz-select :dict="[
                    {name: '20%', value: '20'},
                    {name: '33%', value: '33'},
                    {name: '50%', value: '50'},
                    {name: '66%', value: '66'},
                    {name: '100%', value: '100'},
              ]" :bound="['zdbl']" class="w-100" v-model="chooseId" @change="$forceUpdate()"></yz-select> -->
                      <yz-select
                        :dict="[
                          { name: '50%', value: '50' },
                          { name: '100%', value: '100' },
                        ]"
                        :bound="['zdbl']"
                        class="w-100"
                        v-model="chooseId"
                        @change="$forceUpdate()"
                      ></yz-select>
                    </div>
                  </div>

                  <div class="param-box" v-if="chooseId.sfxtzd != 1">
                    <div class="param-name">字段长度</div>
                    <div class="col-sm-10 p-0 flexList">
                      <input
                        type="number"
                        v-model="chooseId.zdcd"
                        class="form-control w-100 h30"
                      />
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="flexList w-100" style="height: 60px">
            <input
              type="text"
              name=""
              value=""
              class="col-lg-2 h30 form-control"
              v-model="mouleName"
              placeholder="命名该报名表名称"
            />
            <div class="flexList" style="margin-left: 25px">
              <div class="form-check mr-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value
                  id="defaultCheck1"
                  v-model="isSaveMold"
                  @click="changeChecked"
                />
                <label class="form-check-label" for="defaultCheck1"
                  >保存为模板</label
                >
              </div>
              <!-- <button @click="saveMould()">保存</button> -->
              <button
                type="button"
                class="btn btn-info h35 mr-2 sub-btn"
                @click="success()"
              >
                保存表单，进入下一步
              </button>
              <!-- <button type="button"  class="btn btn-info h30 ml-2" @click="success(true)">点击生成报名表名</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="showUeditor" centered title="提示" size="lg" hide-footer>
      <div class="ueditor">
        <vue-ueditor-wrap v-model="chooseId.tsxx" :config="UEditorConfig"></vue-ueditor-wrap>
      </div>
      <div class="mt-3 text-right">
        <b-button variant="secondary" @click="showUeditor = false">关闭</b-button>
        <!-- <b-button variant="primary" @click="saveUeditorContent">保存</b-button> -->
      </div>
    </b-modal>

    <!-- 弹窗开始 -->
    <!--添加机构用户-->
    <b-modal
      v-model="showComp"
      centered
      :title="dialogTitle"
      size="lg"
      title-class="font-18"
      hide-footer
    >
      <div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 12%">标&emsp;&emsp;题：</label>
          <div class="col-sm-10 p-0 flexList">
            <input v-model="addForm.name" class="form-control w-100 h30" />
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 12%">类&emsp;&emsp;型：</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
              :dict="[
                { name: '输入框', value: 'input' },
                { name: '文本域', value: 'textarea' },
                { name: '选择框', value: 'select' },
                { name: '开关', value: 'switch' },
                { name: '单选', value: 'radio' },
                { name: '日期', value: 'date' },
                { name: '月份', value: 'month' },
                { name: '材料上传类型', value: 'file' },
                { name: '审核意见', value: 'shyj' },
                { name: '报名声明', value: 'bmsm' },
              ]"
              :bound="['type']"
              v-model="addForm"
              :readOnly="isDisabled"
              class="w-100"
            ></yz-select>
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 12%">宽&emsp;&emsp;度：</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
              :dict="[
                { name: '50%', value: '50' },
                { name: '100%', value: '100' },
              ]"
              :bound="['width']"
              v-model="addForm"
              :readOnly="isDisabled"
              class="w-100"
            ></yz-select>
          </div>
        </div>
        <div
          class="flexList check-distri mb-3"
          v-if="
            'radio' == addForm.type ||
            'select' == addForm.type ||
            'selectEx' == addForm.type
          "
        >
          <label class="mb-0" style="width: 12%">关联字典：</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
              :dict="[
                { name: '是', value: 1 },
                { name: '否', value: 0 },
              ]"
              :bound="['glzdb']"
              v-model="addForm"
              :readOnly="isDisabled"
              @change="$forceUpdate()"
              class="w-100"
            >
            </yz-select>
          </div>
        </div>
        <div
          class="flexList check-distri mb-3"
          v-if="
            ('radio' == addForm.type ||
              'select' == addForm.type ||
              'selectEx' == addForm.type) &&
            addForm.glzdb == 1
          "
        >
          <label class="mb-0" style="width: 12%">选择字典：</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
              :dict="[
                { name: '民族 dict_mz', value: 'dict_mz' },
                { name: '性别 dict_xb', value: 'dict_xb' },
                { name: '政治面貌 dict_zzmm', value: 'dict_zzmm' },
                { name: '婚姻状况 dict_hyzt', value: 'dict_hyzt' },
                { name: '学历 dict_xl', value: 'dict_xl' },
                { name: '学位 dict_xw', value: 'dict_xw' },
              ]"
              :bound="['dict']"
              v-model="addForm"
              :readOnly="isDisabled"
              class="w-100"
            ></yz-select>
          </div>
        </div>
        <div
          class="flexList check-distri mb-4"
          v-if="
            ('radio' == addForm.type ||
              'select' == addForm.type ||
              'selectEx' == addForm.type) &&
            addForm.glzdb == 0
          "
        >
          <label class="mb-0" style="width: 12%">输入字典：</label>
          <div class="col-sm-10 p-0 flexList">
            <textarea
              rows="3"
              v-model="addForm.zdkxz"
              maxlength="1000"
              :disabled="isDisabled"
              class="form-control w-100"
            >
            </textarea>
            <div class="db-font">(以|隔开)</div>
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 12%">最大长度：</label>
          <div class="col-sm-10 p-0 flexList">
            <input
              type="text"
              v-model="addForm.length"
              maxlength="50"
              placeholder="必须大于零"
              :disabled="isDisabled"
              class="form-control w-100 h30"
            />
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 12%">提示信息：</label>
          <div class="col-sm-10 p-0 flexList">
            <textarea 
              rows="3"
              style="line-height: 1.5;"
              v-model="addForm.placeholder"
              :disabled="isDisabled"
              class="form-control w-100 h30"
            />
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 12%">是否必填：</label>
          <div class="col-sm-10 p-0 flexList">
            <yz-select
              :dict="[
                { name: '必填', value: true },
                { name: '非必填', value: false },
              ]"
              :bound="['must']"
              v-model="addForm"
              :readOnly="isDisabled"
              class="w-100"
            ></yz-select>
          </div>
        </div>
        <div class="flexList check-distri mb-3">
          <label class="mb-0" style="width: 12%">绑定字段：</label>
          <div class="col-sm-10 p-0 flexList">
            <input
              v-model="addForm.bound"
              :disabled="isDisabled"
              class="form-control w-100 h30"
            />
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <button
          type="button"
          class="btn btn-info h30 w-md mr-3"
          @click="submit"
        >
          确定
        </button>
        <button
          type="button"
          class="btn btn-secondary h30 w-md"
          @click="showComp = false"
        >
          取消
        </button>
      </div>
    </b-modal>
    <!--    打印预览-->
    <div class="container" v-if="ksbmbh">
      <div id="pdf" style="display: none;">
        <div class="kstitle">
          <div
            class="page-title-box form-red-title d-flex align-items-center justify-content-center"
          >
            {{ this.examForm.dymc }}
          </div>
          <div class="d-flex justify-content-end">
            <span class="ml-3" style="font-size: 14px">
              报名时间：{{ this.examForm.createTime }}
            </span>
          </div>
        </div>
        <div class="show card pb-2">
          <div class="outerCotent form-sure jbxx" style="border-bottom: 0">
            <div
              class="pay-money d-flex align-items-center justify-content-between pt-3 pb-3 title"
              style="border-top: 1px solid #dfdfdf"
            >
              <div>基本信息</div>
            </div>
            <div class="msgContent flexList fs-xs value">

              <examForm
                v-model="nullForm"
                :ksbmbh="ksbmbh"
                :printflag="true"
                :readOnly="true"
                :kxgList="[]"
                :showType="'print'"
                :print="true"
              ></examForm>
            </div>
          </div>
        </div>
      </div>
      <div id="printId" style="display: none; width: 970px"></div>
    </div>

    <!-- 弹窗结束-->
  </Layout>
</template>
